<template>
  <!--Header Banner-->
  <section class="header-banner">
    <img
      src="@/assets/images/KVAD236_Kardiyoloji_Almanağ_Açılış_Sayfası_Etkinlik_1600x500-3.jpg"
    />
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>
